import { ContentWrapper } from 'components';
import { FC, useState } from 'react';

import Typography from '../../Typography';

export const LoginEmailDialog = () => (
	<ContentWrapper
		title="Wait A Sec"
		heading={<span>For security reasons, we sent you an email.<br />Please click on it to continue.</span>}
		icon="verifyEmailIcon"
	>
		<Typography variant="h5" className="pb-8 pt-12 text-center lg:px-44">
			Don't worry, we'll wait.
		</Typography>
	</ContentWrapper>
);
