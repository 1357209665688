import BattlefieldLayout from 'components/Layouts/BattlefieldLayout';
import useWeb3 from 'hooks/useWeb3';
import { useRouter } from 'next/router';
import { SyntheticEvent, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useGlobalState } from 'state';

import { LoginEmailDialog } from '../components/Battlefield/login/LoginEmailDialog';
import { requestLogin } from '../state/actions/user';

const BattlefieldLogin = ({ i18n }) => {
	const [{ web3, user }, dispatch] = useGlobalState();
	const router = useRouter();
	useWeb3({});

	const { addToast } = useToasts();

	useEffect(() => {
		if (web3.accounts.current) {
			requestLogin(web3.accounts.current)
				.then(dispatch)
				.catch((error) => {
					addToast(error.message, { appearance: "error" });
				});
		}
	}, [web3.accounts?.current]);

	return (
		<div
			style={{
				alignItems: "center",
				height: "80vh",
				display: "flex",
				justifyContent: "center",
			}}
		>
			<div
				className="fixed w-full h-full md:h-3/5"
				style={{
					backgroundImage: `url('/static/images/backgrounds/1.png')`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					zIndex: -2,
					width: "100%",
					top: 0,
				}}
			/>
			<LoginEmailDialog />
		</div>
	);
};

export const getServerSideProps = async () => {
	const i18n = await require("public/static/i18n/en/battlefield.json");

	return {
		props: {
			i18n,
		},
	};
};

BattlefieldLogin.getCustomLayout = function getCustomLayout(
	Component,
	pageProps
) {
	return <BattlefieldLayout Component={Component} pageProps={pageProps} />;
};

export default BattlefieldLogin;
